<!-- eslint-disable sonarjs/no-duplicate-string -->
<template>
  <Sect id="SectionApvaIntro">
    <Wrap class="lg">
      <h2>Įsigykite elektrinės dalį iki 30% pigiau su APVA parama</h2>
      <Row class="points">
        <Column v-for="(card, index) in cards" :key="index" :md="4">
          <Card class="apvaPoint">
            <Icon :name="card.icon" />
            <h3>{{ card.label }}</h3>
          </Card>
        </Column>
      </Row>
    </Wrap>
    <Wrap class="md">
      <div class="textContainer">
        <p>
          Saulės elektrinių finansavimas ir parama Lietuvos gyventojams – pagalba žmonėms, norintiems prisidėti prie alternatyvios energijos naudojimo ir aplinkosaugos. Pasinaudokite patogiomis valstybės finansavimo galimybėmis ir atsinaujinančią energiją naudokite pigiau.
        </p>
        <p>
          Paramą inicijuoja APVA – LR Aplinkos ministerijos Aplinkos projektų valdymo agentūra, administruojanti Europos Sąjungos fondų (ISPA, Europos regioninės plėtros, Sanglaudos fondų, LIFE+ programos), LAAIF, Klimato kaitos, Atliekų tvarkymo, Aplinkos apsaugos rėmimo programų ir valstybės lėšomis finansuojamus aplinkos sektoriaus projektus.
        </p>
      </div>
    </Wrap>
  </Sect>
</template>

<script>
import Card from 'components/globals/Card'
import { getters } from 'views/utils'

export default {
  components: { Card },
  computed: {
    cards() {
      return [
        {
          icon: 'coin',
          label: 'Suteikiama parama iki 3 230 Eur',
        },
        {
          icon: 'calculator',
          label: 'Gavus paramą, investicija atsipirks per 4-5 metus',
        },
        {
          icon: 'calendar-clock',
          label: `Paraiškos priimamos iki: ${this.getSnippetValue('apvaStage')}`,
        },
      ]
    },
    ...getters('getSnippetValue'),
  },
}
</script>

<style lang="scss">
#SectionApvaIntro {
  h2 {
    @include dotAbove(1.5rem);
    margin: 3rem 0;
  }
  // display: flex;
  // padding-left: 2rem;
  // position: relative;

  .points {
    gap: 1rem;
    margin: 4rem 0;
    @include md {
      gap: unset;
    }
  }

  .textContainer {
    margin-bottom: 3rem;
    margin-top: 3rem;

    > p {
      margin-bottom: 2rem;
    }
  }

  .apvaPoint {
    background: $white;
    border-radius: $radius 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 3rem;
    width: 100%;
    @include md {
      margin-bottom: 0;
    }

    h3 {
      font-weight: $bold;
    }

    .Icon {
      color: $blue;
      font-size: 6rem;
    }

  }
}
</style>
