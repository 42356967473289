<template>
  <div id="ApvaIndividuals">
    <Hero src="../../assets/apva/hero.jpg">
      <h1>Valstybės parama gyventojams</h1>
    </Hero>
    <Wrap class="lg">
      <SectionApvaIntro />
    </Wrap>
    <SectionApvaInfo />
    <SectionApvaSteps />
  </div>
</template>

<script>
import Hero from '../components/landing/Hero'
import Meta from '../mixins/Meta'
import SectionApvaInfo from './components/SectionApvaInfo'
import SectionApvaIntro from './components/SectionApvaIntro'
import SectionApvaSteps from './components/SectionApvaSteps'

export default {
  mixins: [
    Meta(
      {
        inner: 'APVA Parama',
        separator: '|',
        complement: 'Saulės bendruomenė',
      },
      undefined,
      require('@/assets/social/homepage.jpg'),
    ),
  ],
  components: {
    Hero,
    SectionApvaInfo,
    SectionApvaIntro,
    SectionApvaSteps,
  },
  data() {
    return {
      posts: null,
    }
  },
}
</script>

<style lang="scss">
#ApvaIndividuals {
  background: $white-ter;

  .Hero {
    @include roundSvg('../../assets/ellipse.svg');

    h1 {
      @include dotBefore(8rem);
    }

    img {
      filter: brightness(0.4);
    }
  }

  .Sect {
    margin-top: 4rem;

    &:first-of-type, &:last-of-type {
      margin-top: 0;
    }
  }

}

</style>
