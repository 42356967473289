<template>
  <div class="Hero">
    <div v-if="hasImage" class="image">
      <Pic :formats="formats" :src="src" />
    </div>
    <div class="Hero-body">
      <Wrap class="lg">
        <slot />
      </Wrap>
    </div>
    <div v-if="$slots.outside" class="outside">
      <slot name="outside" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formats: {
      type: Array,
      default: () => [],
    },
    src: String,
  },
  computed: {
    hasImage() {
      return !!this.src
    },
  },
}
</script>

<style lang="scss">
.Hero {
  align-items: stretch;
  background-color: $black-bis;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60vh;
  overflow: hidden;
  // padding: 4.6rem 0.5rem 0 4.6rem;
  padding: 0 1rem;
  position: relative;
  z-index: 1;
  @include md {
    // padding-bottom: 6rem;
    // padding-top: 6rem;
    padding: 4.6rem 0.5rem 0 4.6rem;
  }
  @include lg {
    padding-bottom: 12rem;
    padding-top: 12rem;
  }
  @include xl {
    padding-top: 12rem;
  }

  h1 {
    font-size: $h2;
    @include md {
      font-size: $h1;
    }
  }

  .Row {
    > div {
      margin-bottom: 2rem;
      @include lg {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  > .image {
    img {
      bottom: 0;
      filter: brightness(0.2);
      left: 0;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  .outside {
    z-index: 3;
  }

  > .Hero-body {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    z-index: 2;

    > .Wrap {
      flex-grow: 1;
      flex-shrink: 1;
      margin: 0 auto;
      padding: 0;
      @include xs {
        padding: 0 1.5rem;
      }
      @include xl {
        padding: 0 2rem;
      }
    }
  }
}
</style>
