<template>
  <div
    class="CardApvaStep">
    <div class="details wrap">
      <div class="numberContainer">
        <span class="number">
          {{ step.number }}
        </span>
      </div>
      <div class="info">
        <h3 class="title">
          {{ step.title }}
        </h3>
        <p v-html="step.text" />
      </div>
    </div>
    <div class="preview">
      <Pic
        :src="step.image" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: Object,
  },
}
</script>

<style lang="scss">
.CardApvaStep {
  background: $white-ter;
  border-radius: $radius 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  @include lg {
    flex-direction: row;
    height: 35rem;
  }

  .numberContainer {
    // @include dotBefore(6rem);
    margin-left: 4rem;
    margin-top: 2.5rem;
    position: relative;

    &::before {
      background: $blue;
      border-radius: $radius-round;
      content: ' ';
      height: 6rem;
      left: -4rem;
      position: absolute;
      top: -2rem;
      width: 6rem;
    }

    .number {
      color: $black;
      font-size: $h1;
      position: relative;
      z-index: 2;
    }
  }

  .wrap {
    padding: 2rem 3rem 0;
  }

  .details {
    // display: flex;
    // flex-direction: column;
    height: 40rem;
    // justify-content: flex-start;
    white-space: normal;
    @include lg {
      height: unset;
      // justify-content: space-evenly;
      width: 50rem;
    }

    .info {
      text-align: left;

      p {
        font-size: 1rem;
        line-height: $lh * 1.2;
      }
    }

    > * {
      margin-bottom: 1rem;
    }

    .title {
      font-size: 1.8rem;
      margin-bottom: 1rem;
      text-align: left;
    }

    .date {
      color: $blue;
    }
  }

  .preview {
    border-radius: $radius 0;
    height: 20rem;
    overflow: hidden;
    position: relative;
    // height: 25rem;
    // opacity: 0.66;
    width: 30rem;
    @include lg {
      height: unset;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
</style>
