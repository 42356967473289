<!-- eslint-disable sonarjs/no-duplicate-string -->
<template>
  <Sect id="SectionApvaSteps">
    <h2>Kaip gauti paramą?</h2>
    <div class="mainContainer">
      <HorizontalScroll v-if="steps" :itemsCount="steps.length">
        <!-- <div ref="projects" class="projects"> -->
        <CardApvaStep
          v-for="(post, index) in steps"
          :key="index"
          :step="post" />
      <!-- </div> -->
      </HorizontalScroll>
    </div>
  </Sect>
</template>

<script>
import CardApvaStep from './CardApvaStep'
import HorizontalScroll from 'views/components/HorizontalScroll'

export default {
  components: { CardApvaStep, HorizontalScroll },
  data() {
    return {
      steps: [
        {
          number: '01',
          title: 'Išsirinkite jums tinkamiausią saulės elektrinės galią',
          image: '../../../assets/apvaSteps/1.jpg',
          // eslint-disable-next-line quotes
          text: `Visų pirma, svarbu tinkamai įsivertinti reikiamą saulės elektrinės galios kiekį, kuris atitinka jūsų elektros energijos suvartojimą. Pasinaudoję „Saulės bendruomenės“, <a href="/#Calculator" title="Skaičiuoklė">skaičiuokle</a> galėsite sužinoti ne tik jums rekomenduojamą elektrinės galią, bet ir investicijos dydį bei atsipirkimo laikotarpį.`,
        },
        {
          number: '02',
          title: 'Pateikite paraišką APVA paramai gauti',
          image: require('../../../assets/apvaSteps/2.jpg'),
          // eslint-disable-next-line quotes
          text: `Sužinoję, kokio galingumo saulės elektrinę planuojate įsigyti, užpildykite ir pateikite paraišką APVA paramai gauti. Paraišką pateikti galite APVA intenretinėje svetainėje: <a href="https://apvis.apva.lt" target="_blank" title="APVA svetainė">apvis.apva.lt.</a> Atsisiųskite išsamią paraiškos pildymo instrukciją <a href="https://urbanwebstack-uploadspublicbucket-52in24jckklk.s3.amazonaws.com/Kaip_pateikti_islaidu_kompensavimo_prasyma_APVA_sistemoje_2_a80eccf8d4.pdf" target="_blank" title="Paraiškos pildymo instrukcija">iš čia.</a> Paraišką būtina pateikti dar prieš jums įsigyjant elektrinės dalį iš saulės parko.<br>Įspėjimas. Paramos kvietimai skelbiami tik kelis kartus per metus. Apie artimiausią kvietimą galite sužinoti APVA internetinėje svetainėje: <a href="https://www.apva.lt" target="_blank" title="APVA svetainė">www.apva.lt</a>`,
        },
        {
          number: '03',
          title: 'Rezervuokite elektrinės dalį',
          image: require('../../../assets/apvaSteps/3.jpg'),
          // eslint-disable-next-line quotes
          text: `Rezervuokite norimą elektrinės dalį viename iš „Saulės bendruomenės“ saulės parkų. Visą informaciją apie mūsų saulės parkus ir rezervaciją rasite <a href="/saules-parkai" title="Saulės parkai">čia.</a>`,
        },
        {
          number: '04',
          title: 'Įsigykite saulės elektrinę',
          image: require('../../../assets/apvaSteps/4.jpg'),
          // eslint-disable-next-line quotes
          text: `Aplinkos projektų valdymo informacinė sistemoje APVIS (<a href="https://apvis.apva.lt/" target="_blank" title="">https://apvis.apva.lt/</a>), savo paskyroje sulaukę žinutės, kad jūsų prašymas buvo patvirtintas ir jums bus suteikta APVA parama, įsigykite jūsų rezervuotą saulės elektrinę. Kad galėtumėte įsigyti rezervuotą saulės elektrinės dalį, turite būti sumokėję avansinį mokestį ir taip patvirtinti sudarytą sutartį. Sumokėję avansą galite laukti saulės parko statybų pabaigos ir elektrinės perdavimo klientams.<br><br>Pastaba! Įsigyti nutolusio saulės parko dalį galite ir prieš pateikdami paraišką APVA. Jeigu numatytos paramos gauti nepavyktų, o įsigyti saulės parko dalies pilna kaina nenorėtumėte - grąžinsime pinigus ir nutrauksime sutartį.`,
        },
        {
          number: '05',
          title: 'Parkas pradeda veikti',
          image: require('../../../assets/apvaSteps/5.jpg'),
          // eslint-disable-next-line quotes
          text: `Pasibaigus saulės parko rangos darbams ir parkui pradėjus generuoti elektros energiją, jums bus suformuotas galutinio mokėjimo prašymas. Atlikę mokėjimą, gausite visus reikiamus dokumentus, susijusius su jūsų saulės parko dalimi.`,
        },
        {
          number: '06',
          title: 'Pateikite išlaidų kompensavimo prašymą APVIS sistemoje',
          image: require('../../../assets/apvaSteps/6.jpg'),
          // eslint-disable-next-line quotes
          text: `Prisijungę prie savo paskyros, reikiamus dokumentus pateikite APVIS sistemoje. „Saulės bendruomenės“ pasirūpins šių dokumentų perdavimu jums. Čia galite pasitikrinti išlaidų kompensavimo prašymui reikalingų dokumentų sąrašą.`,
        },
        {
          number: '07',
          title: 'Susigrąžinkite iki 30% investicijos',
          image: require('../../../assets/apvaSteps/7.jpg'),
          // eslint-disable-next-line quotes
          text: `Sėkmingai pateikus išlaidų kompensavimo prašymą, APVA jums išmokės nustatyto dydžio kompensaciją per 60 kalendorinių dienų nuo dokumentų pateikimo.`,
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.Sect {
  margin-top: 0;
}

#SectionApvaSteps {
  background: $white;
  padding-bottom: 6rem;
  padding-top: 8rem;

  h2 {
    @include dotAbove(1.5rem);
    margin-bottom: 4rem;
  }

  .viewMore {
    display: flex;
    justify-content: center;
    margin-top: 4rem;

    > a {
      width: 18rem;
    }
  }
}
</style>
